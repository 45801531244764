import feture1 from "../images/feture1.svg";
import feture2 from "../images/feture2.svg";
import feture3 from "../images/feture3.svg";
import feture4 from "../images/feture4.svg";

export const FetureData = [
  {
    id: 1,
    img: feture1,
    name: "100% Satisfaction",
    para: "There are many variations of passages of Lorem Ipsum available,but the majority have.",
  },
  {
    id: 2,
    img: feture2,
    name: "Flexible & Cost-Effective",
    para: "There are many variations of passages of Lorem Ipsum available,but the majority have.",
  },
  {
    id: 3,
    img: feture3,
    name: "Annual Pass Programs",
    para: "There are many variations of passages of Lorem Ipsum available,but the majority have.",
  },
  {
    id: 4,
    img: feture4,
    name: "Professional Gardener",
    para: "There are many variations of passages of Lorem Ipsum available,but the majority have.",
  },
];
