import React from "react";
import caseStudyDetails from "../images/caseStudyDetails.png";
import caseStudyD1 from "../images/caseDetails1.png"
import caseStudyD2 from "../images/caseDetails2.png"
import caseStudyD3 from "../images/caseDetails3.png"
import caseStudyD4 from "../images/caseDetails4.png"
import caseStudyD5 from "../images/caseDetails5.png"
import caseStudyD6 from "../images/caseDetails6.png"
import Highlights from "./Highlights";

const CaseStudyDetails = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col gap-5">
          <h2>Project Overview</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printin and typesetting
            industry. Lorem Ipsum has been the industry’s standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a Lorem Ipsum has been the industry’s
            standard dummy text ever since the 1500s, specimen book. It has
            survived not only five centuries, but also the leap into electronic
            typesetting, remaining essentially unchanged. typesetting industry.
          </p>
          <div className="border-t-2 border-troo-secondary bg-troo-tertiary p-5 flex lg:flex-row flex-col justify-between">
            <div className="flex flex-col gap-4">
              <h4 className="font-normal">
                Client Name: <span className="font-bold"> Bobert Samual</span>
              </h4>
              <h4 className="font-normal">
                End Date: <span className="font-bold"> 26 June, 2023</span>
              </h4>
            </div>
            <div className="flex flex-col gap-4">
              <h4 className="font-normal">
                Location: <span className="font-bold"> 5 main Street, USA</span>
              </h4>
              <h4 className="font-normal">
                Area(sq.mt.): <span className="font-bold"> 17500</span>
              </h4>
            </div>
            <div className="flex flex-col gap-4">
              <h4 className="font-normal">
                Category: <span className="font-bold"> Lawn Renovation</span>
              </h4>
              <h4 className="font-normal">
                Facilities:{" "}
                <span className="font-bold"> jogging track, Plants Plotes</span>
              </h4>
            </div>
            <div className="flex flex-col gap-4">
              <h4 className="font-normal">
                Starting Date: <span className="font-bold"> 24 June, 2023</span>
              </h4>
            </div>
          </div>
          <img src={caseStudyDetails} alt="caseStudyDetails" />
          <h3>The Challenges</h3>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>
          <h3>The Solutions</h3>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>
          <h3>Garden Image Gallery</h3>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't.</p>
          <div className="flex flex-wrap lg:flex-nowrap w-full justify-between gap-5">
            <img className="w-full" src={caseStudyD1} alt="caseStudyDetail1" />
            <img className="w-full" src={caseStudyD2} alt="caseStudyDetail2" />
            <img className="w-full" src={caseStudyD3} alt="caseStudyDetail3" />
          </div>
          <div className="flex flex-wrap lg:flex-nowrap w-full justify-between gap-5">
            <img className="w-full" src={caseStudyD4} alt="caseStudyDetail1" />
            <img className="w-full" src={caseStudyD5} alt="caseStudyDetail2" />
            <img className="w-full" src={caseStudyD6} alt="caseStudyDetail3" />
          </div>
        </div>
        <Highlights isFaq/>
      </div>
    </div>
  );
};

export default CaseStudyDetails;
