import { FaStar } from 'react-icons/fa';
import client1 from "../images/feedback1.png"
import client2 from "../images/feedback2.png"
import client3 from "../images/feedback3.png"
import client4 from "../images/feedback4.png"
import client5 from "../images/feedback5.png"
import client6 from "../images/feedback6.png"
import client7 from "../images/feedback7.png"
import client8 from "../images/feedback8.png"

export const FeedbackData = [
    {
        id : 1,
        img : client1,
        sub : "“Great Gardener Team”",
        name : "Julia Heartbreak",
        desg : "CEO & Founder",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 2,
        img : client2,
        sub : "“Outstanding Work”",
        name : "Luck Harper",
        desg : "CEO & Founder",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 3,
        img : client3,
        sub : "“Great Gardener Team”",
        name : "Katherine Hintz",
        desg : "CEO & Founder",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 4,
        img : client4,
        sub : "“Outstanding Work”",
        name : "Abraham Ondricka",
        desg : "CEO & Founder",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 5,
        img : client5,
        sub : "“Great Gardener Team”",
        name : "Shari Runolfsson",
        desg : "CEO & Founder",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 6,
        img : client6,
        sub : "“Great Gardener Team”",
        name : "Jesus Grant",
        desg : "CEO & Founder",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 7,
        img : client7,
        sub : "“Outstanding Work”",
        name : "Paul Daniel",
        desg : "CEO & Founder",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 8,
        img : client8,
        sub : "“Great Gardener Team”",
        name : "Betsy Olson",
        desg : "CEO & Founder",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    }
]