import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import CaseStudyDetails from '../../Components/CaseStudyDetails'
import useDocumentTitle from '../../Hooks/PageTitle'

const CaseStudyDetailsPage = () => {
  useDocumentTitle("TRoo Gardener | Case Study Details")
  return (
    <Layout>
        <HeroGlobal title="Bird Wave Bridge Project"/>
        <CaseStudyDetails/>
    </Layout>
  )
}

export default CaseStudyDetailsPage