import React from "react";
import { motion } from "framer-motion";

const Heading = ({ heading, title, textLight, isCenter }) => {
  return (
    <motion.div
    initial={{ scale: 0.7 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }}
      className={`flex flex-col  gap-6 ${
        textLight ? "text-troo-white" : "text-troo-black"
      } ${isCenter ? "text-center items-center" : "text-left"}`}
    >
      <h2 className="max-w-[750px]">{heading}</h2>
      <div
        className={` text-troo-black p-2  ${
          textLight ? "text-troo-white" : "text-troo-black"
        }`}
      >
        <h4 className="font-normal">{title}</h4>
      </div>
    </motion.div>
  );
};

export default Heading;
