import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Routes/Home";
import { useEffect } from "react";
import AboutPage from "./Routes/AboutPage";
import CaseStudyPage from "./Routes/Pages/CaseStudyPage";
import CaseStudyDetailsPage from "./Routes/Pages/CaseStudyDetailsPage";
import TeamPage from "./Routes/Pages/TeamPage";
import TeamDetailsPage from "./Routes/Pages/TeamDetailsPage";
import TesimonialPage from "./Routes/Pages/TesimonialPage";
import FaqPage from "./Routes/Pages/FaqPage";
import Error404Page from "./Routes/Pages/Error404Page";
import BlogPage from "./Routes/Blogs/BlogPage";
import ServiceDetailsPage from "./Routes/Service/ServiceDetailsPage";
import BlogDetailsPage from "./Routes/Blogs/BlogDetailsPage";
import ContactPage from "./Routes/ContactPage";
import ServicePage from "./Routes/Service/ServicePage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about-us" element={<AboutPage/>} />
        <Route path="/our-service" element={<ServicePage/>} />
        <Route path="/our-service/:slug" element={<ServiceDetailsPage/>} />
        <Route path="/case-study" element={<CaseStudyPage/>} />
        <Route path="/case-study/:slug" element={<CaseStudyDetailsPage/>} />
        <Route path="/our-team" element={<TeamPage/>} />
        <Route path="/our-team/:slug" element={<TeamDetailsPage/>} />
        <Route path="/testimonial" element={<TesimonialPage/>} />
        <Route path="/faq" element={<FaqPage/>} />
        <Route path="/error-404" element={<Error404Page/>} />
        <Route path="/our-blogs" element={<BlogPage/>} />
        <Route path="/our-blogs/:slug" element={<BlogDetailsPage/>} />
        <Route path="/contact-us" element={<ContactPage/>} />
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
