import React from 'react'
import Layout from '../Components/Layout'
import HeroGlobal from '../Components/HeroGlobal'
import Contact from '../Components/Contact'
import useDocumentTitle from '../Hooks/PageTitle'

const ContactPage = () => {
  useDocumentTitle("TRoo Gardener | Contact Page")
  return (
    <Layout>
        <HeroGlobal title="Let’s Talk With Us"/>
        <Contact/>
    </Layout>
  )
}

export default ContactPage