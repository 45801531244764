import work1 from "../images/work1.svg"
import work2 from "../images/work2.svg"
import work3 from "../images/work3.svg"
import work4 from "../images/work4.svg"

export const WorkData =[
    {
        id : 1,
        img : work1,
        name : "Talk To Experts",
        sub : "Step - 1",
        para : "There are many variations of passages als of Lorem Ipsum available, but the majority have variations."
    },
    {
        id : 2,
        img : work2,
        name : "Design Proposal",
        sub : "Step - 2",
        para : "There are many variations of passages als of Lorem Ipsum available, but the majority have variations."
    },
    {
        id : 3,
        img : work3,
        name : "TStart Planting",
        sub : "Step - 3",
        para : "There are many variations of passages als of Lorem Ipsum available, but the majority have variations."
    },
    {
        id : 4,
        img : work4,
        name : "Enjoy In Your Garden",
        sub : "Step - 4",
        para : "There are many variations of passages als of Lorem Ipsum available, but the majority have variations."
    },
]