import React from "react";
import Fetures from "./Fetures";
import Heading from "./Heading";
import { PortfolioData } from "../Data/PortfolioData";
import { GoArrowUpRight } from "react-icons/go";
import { Link } from "react-router-dom";

const Portfolio = () => {
  return (
    <div className="w-[99%] m-auto bg-troo-black ">
      <Fetures/>
      <div className="main_container2 ">
        <div className="pb-12">
          <Heading
            title={
              <>
                There are many variations of passages of Lorem Ipsum available,
                but the majority <br />
                have suffered alteration in some form, by injected humour.
              </>
            }
            heading={
              <>
                The Greatest Work Showcase <br />
                For Our Client’s
              </>
            }
            isCenter
            textLight
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 relative mb-12 lg:-mb-24">
          {PortfolioData.slice(0, 4).map((e, i) => (
            <div >
             <Link key={i} className="group" to={`/case-study/${e.slug}`}>
             <div className="relative before:content-[''] before:bg-troo-black before:bg-opacity-50 before:absolute before:bottom-0 before:w-full before:h-0 group-hover:before:h-full before:transition-all before:ease-in-out before:duration-300 flex justify-center items-center">
                <img className="w-full" src={e.img} alt={e.name} />
                <div className="absolute  scale-0 group-hover:scale-100 transition-all duration-700 ease-in-out">
                  <GoArrowUpRight className="p-4 w-14 h-14 bg-troo-primary rounded-full" />
                </div>
              </div>
              <div className="p-8 flex flex-col gap-5 bg-troo-tertiary group-hover:bg-troo-secondary group-hover:text-troo-white transition-all ease-in-out duration-500 border border-troo-black border-opacity-10">
                <p>{e.sub}</p>
                <h4>{e.name}</h4>
              </div>
             </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
