import React from "react";
import { BrandData } from "../Data/BrandData";
import { motion } from "framer-motion";

const Brands = ({isAbout}) => {
  return (

    <div className={`bg-troo-tertiary  ${isAbout?"pt-20 lg:pt-44 pb-12 lg:pb-24":"py-12 lg:py-24"}`}>
    <div className=" main_container1">
        <div className="text-center mb-12">
          <h3>We Collabrate <span className="text-troo-secondary">250+</span> Company & Organization</h3>
        </div>
        <div className="flex items-center flex-wrap justify-center gap-1">
          {BrandData.map((e, i) => (
            <motion.div
              initial={{ y: 30, opacity: 0, scale: 0.5 }}
              whileInView={{ y: 0, opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: "100", delay: 0.1 * i }}
              className="px-4 py-4 max-w-[207px]"
              key={i}
            >
              <img className="w-full" src={e.img} alt={e.img} />
            </motion.div>
          ))}
        </div>
    </div>
    </div>

  );
};

export default Brands;
