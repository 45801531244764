import React from "react";
import abou1 from "../images/about1.png";
import abou2 from "../images/about2.png";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { motion } from "framer-motion";

const About = ({isHome}) => {
  return (
    <div className={` w-full h-full bg-no-repeat ${isHome? "bg-troo-secondary bg-about-Bg ":"bg-about-Bg2 bg-top"}`}>
      <div className={`main_container1  ${isHome?"pt-12 lg:pt-24":"py-12 lg:py-24"}`}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 ">
          <div className={`flex flex-col gap-8 ${isHome?"py-12":""}`}>
            <motion.h2
            initial={{ scale: 0.7 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }}
            className={`${isHome? "text-troo-white":"text-troo-black"}`}>
              We Are TRoo Gardener,Complete Solution for Your Landscaping
              Vision.
            </motion.h2>
            {!isHome && <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>}
            <img className="w-full" src={abou1} alt="about1" />
          </div>
          <div className={`flex flex-col gap-6 relative  ${isHome?"text-troo-white lg:pt-12 lg:-mb-16" : "text-troo-black"}`}>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable.
            </p>
            <div>
              <Link
                to="/about-us"
                className={`btn1 bg-transparent border ${isHome?" border-dashed border-troo-white  hover:bg-troo-white":"border-troo-black hover:bg-troo-primary hover:text-troo-white"}`}
              >
                Learm More About Us <FiChevronRight />
              </Link>
            </div>
            <img src={abou2} alt="about2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
