import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { FeedbackData } from "../Data/FeedBackData";
import { Link } from "react-router-dom";

const Testimonials = ({ isTestimonial }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isTestimonial) {
      setData(FeedbackData.slice(0, 4));
    } else {
      setData(FeedbackData);
    }
  }, []);
  return (
    <div
      className={` py-12 lg:py-24 ${
        isTestimonial
          ? ""
          : "bg-feedback-bg bg-no-repeat w-full h-full bg-cover"
      }`}
    >
      <div className="main_container1">
        {!isTestimonial && (
          <div className="mb-12">
            <Heading
              title={
                <>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority <br />
                  have suffered alteration in some form, by injected humour.
                </>
              }
              heading={
                <>
                  Explore Our Very Sweet <br />
                  Clients Testimonials
                </>
              }
              isCenter
            />
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {data.map((e, i) => (
            <div
              key={i}
              className="p-5 shadow-lg border hover:border-troo-secondary transition-all duration-500 ease-in-out flex flex-col sm:flex-row gap-5 w-full bg-troo-white"
            >
              <div className="sm:w-[70%]">
                <img className="w-full" src={e.img} alt={e.name} />
              </div>
              <div className="flex flex-col gap-5 ">
                <h4 className="border-b pb-4">{e.sub}</h4>
                <p className="border-b pb-4">{e.para}</p>
                <div className="flex flex-col sm:flex-row  justify-between items-center gap-5 ">
                  <div className="flex items-center gap-5">
                    <div>
                      <h4>{e.name}</h4>
                      <p>{e.desg}</p>
                    </div>
                  </div>
                  <div className="flex gap-2 text-troo-primary bg-troo-primary p-2 bg-opacity-20">
                    {e.rating}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
{
  !isTestimonial && (
    <div className="flex justify-center  mt-12">
    <h4 className="font-normal p-3 border border-dashed border-troo-secondary">
      Want to see more all testimonials?{" "}
      <Link to="/testimonial" className="font-bold text-troo-secondary">Click Here</Link>
    </h4>
  </div>
  )
}
      </div>
    </div>
  );
};

export default Testimonials;
