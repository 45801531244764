export const HeaderData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "about us",
    path: "/about-us",
  },
  {
    title: "our service",
    path: "#",
    subNav: [
      {
        subTitle: "our service",
        path: "/our-service",
      },
      {
        subTitle: "service details",
        path: "/our-service/home-gardening",
      },
    ],
  },
  {
    title: "Pages",
    path: "#",
    subNav: [
      {
        subTitle: "case study",
        path: "/case-study",
      },
      {
        subTitle: "case study details",
        path: "/case-study/bird-wave-bridge-project",
      },
      {
        subTitle: "our team",
        path: "/our-team",
      },
      {
        subTitle: "team details",
        path: "/our-team/alexa-brethfinix",
      },
      {
        subTitle: "testimonial",
        path: "/testimonial",
      },
      {
        subTitle: "faq",
        path: "/faq",
      },
      {
        subTitle: "error 404",
        path: "/error-404",
      },
    ],
  },
  {
    title: " Our Blogs",
    path: "#",
    subNav: [
      {
        subTitle: "our blogs",
        path: "/our-blogs",
      },
      {
        subTitle: "blog details",
        path: "/our-blogs/troo-gardening-blogs-&-news",
      },
    ],
  },
  {
    title: "contact us",
    path: "/contact-us",
  },
];