import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { ServiceData } from "../Data/SeerviceData";
import { Link } from "react-router-dom";
import serviceViewAll from "../images/serviceViewAll.png";
import serviceStricker from "../images/serviceStricker.svg";
import { motion } from "framer-motion";

const Service = ({ isHome }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHome) {
      setData(ServiceData.slice(0, 5));
    } else {
      setData(ServiceData);
    }
  }, []);
  return (
    <div className="pt-12 lg:pt-24 pb-12 lg:pb-44 relative">
      <div className="main_container1">
        {isHome && (
          <div className="mb-12">
            <Heading
              title={
                <>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority <br />
                  have suffered alteration in some form, by injected humour.
                </>
              }
              heading={
                <>
                  We Are TRoo Gardener,Complete <br />
                  Solution for Your Landscaping Vision.
                </>
              }
              isCenter
            />
          </div>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7">
          {data?.map((e, i) => (
            <div
              className="flex flex-col items-center justify-center gap-5 py-8 px-6 border hover:border-troo-secondary group"
              key={i}
            >
              <div className="relative ">
                <img className="rounded-full w-full" src={e.img} alt={e.name} />
                <p className="absolute right-3 outline outline-troo-white top-5 flex justify-center items-center text-troo-white rounded-full bg-troo-black w-8 h-8 group-hover:bg-troo-secondary">
                  0{e.id}
                </p>
              </div>
              <h3>{e.name}</h3>
              <p className="text-center">{e.para}</p>
              <div>
                <Link
                  to={`/our-service/${e.slug}`}
                  className="btn1  group-hover:bg-troo-secondary group-hover:text-troo-white border "
                >
                  Explore Services <span>+</span>
                </Link>
              </div>
            </div>
          ))}
          {isHome && (
            <div className="bg-troo-secondary p-5 flex flex-col justify-center items-center gap-5 text-troo-white">
              <img src={serviceViewAll} alt="serviceViewAll" />
              <h3>View All 5+ More Servicw</h3>
              <p className="text-center">
                There are many variations of passages of Lorem Ipsum
                available,but the majority have suffered alteration in some
                form, by injected humour.
              </p>
              <div>
                <Link
                  to="/our-service"
                  className="btn1 border hover:bg-troo-black group-hover:text-troo-white "
                >
                  View All Services <span>+</span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      {isHome && (
        <div className=" absolute right-0 bottom-0 hidden lg:block">
          <img src={serviceStricker} alt="serviceStricker" />
        </div>
      )}
    </div>
  );
};

export default Service;
