import React from "react";
import logo from "../images/Logo.svg";
import { BiTime } from "react-icons/bi";
import { TbMailOpened } from "react-icons/tb";
import { Link } from "react-router-dom";
import { BsSend } from "react-icons/bs";
const Footer = () => {
  const siteLink = "https://www.troothemes.com/";
  return (
    <div className="lg:bg-footer-Bg bg-no-repeat bg-cover w-full h-full pt-12 bg-troo-secondary">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row justify-between gap-10 pb-12 border-b border-troo-white border-opacity-20">
          <div>
            <img src={logo} alt="logo2" />
          </div>
          <div className="flex gap-3 items-center flex-wrap text-troo-white">
            <div className="w-24 h-[1px] bg-troo-white"></div>
            <Link className="hover:text-troo-primary">Social Media:</Link>
            <Link className="hover:text-troo-primary">Facebook</Link>
            <Link className="hover:text-troo-primary">Twitter</Link>
            <Link className="hover:text-troo-primary">Linkedin</Link>
            <Link className="hover:text-troo-primary">Instagram</Link>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 py-14 text-troo-white">
          <div className="flex flex-col sm:flex-row gap-10 justify-between">
            <div className="bg-troo-white bg-opacity-5 flex flex-col gap-5 justify-center p-6 sm:w-[70%]">
              <p className="pb-6 border-b border-troo-white border-opacity-20 rounded">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer.
              </p>
              <p>Newslatter</p>
              <form className="w-full relative">
                <input
                  type="text"
                  placeholder="Enter your email"
                  className="w-full px-5 sm:px-10 py-3 lg:py-5 bg-transparent border border-troo-white border-opacity-10 rounded"
                />
                <button className="absolute right-0 bg-troo-white text-troo-black h-full font-bold flex items-center px-3 gap-3 bottom-0">
                Subscribe<BsSend />
                </button>
              </form>
            </div>
            <div className="flex flex-col sm:w-[30%]">
              <h4 className="flex items-center gap-2 mb-7">
                <div className="w-5 h-[2px] bg-troo-white"></div>Quick Links
              </h4>
              <div className="flex flex-col gap-4">
                <Link>About Us</Link>
                <Link>Our Services</Link>
                <Link>Case Studies</Link>
                <Link>Testimonials</Link>
                <Link>Our Blogs</Link>
                <Link>Contact Us</Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-10 justify-between">
            <div className="flex flex-col sm:w-[40%]">
              <h4 className="flex items-center gap-2 mb-7">
                <div className="w-5 h-[2px] bg-troo-white"></div>Our Services
              </h4>
              <div className="flex flex-col gap-4">
                <Link>About Us</Link>
                <Link>Our Services</Link>
                <Link>Case Studies</Link>
                <Link>Testimonials</Link>
                <Link>Our Blogs</Link>
                <Link>Contact Us</Link>
              </div>
            </div>
            <div className="flex flex-col sm:w-[60%]">
              <h4 className="flex items-center gap-2 mb-7">
                <div className="w-5 h-[2px] bg-troo-white"></div>Contact With Us
              </h4>
              <div className="flex flex-col gap-5">
                <div className="flex items-center gap-5">
                  <div>
                    <TbMailOpened className="text-2xl"/>
                  </div>
                  <div>
                    <p>+44 123 456 7890 or +44 987 654 3210</p>
                    <p>contact@troogarener.com</p>
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <div>
                    <BiTime className="text-2xl"/>
                  </div>
                  <div>
                    <p>+44 123 456 7890 or +44 987 654 3210</p>
                    <p>contact@troogarener.com</p>
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <div>
                    <TbMailOpened className="text-2xl"/>
                  </div>
                  <div>
                    <p>+44 123 456 7890 or +44 987 654 3210</p>
                    <p>contact@troogarener.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex lg:flex-row flex-col justify-between items-center gap-5 text-troo-white ">
          <p className="bg-troo-white bg-opacity-5 px-6 py-3">
            Copyright © 2024.{" "}
            <Link to={siteLink} className="font-bold">
            TRooThemes
            </Link>
            . All rights reserved.
          </p>
          <div className="flex flex-col md:flex-row gap-5 bg-troo-white bg-opacity-5 px-6 py-3">
            <Link>Terms & Conditions</Link>
            <Link>Cookie Policy</Link>
            <Link>Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
