import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import Service from '../../Components/Service'
import useDocumentTitle from '../../Hooks/PageTitle'

const ServicePage = () => {
  useDocumentTitle("TRoo Gardener | Service Page")
  return (
    <Layout>
        <HeroGlobal title="What We Do"/>
        <Service/>
    </Layout>
  )
}

export default ServicePage