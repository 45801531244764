import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import Teams from '../../Components/Teams'
import useDocumentTitle from '../../Hooks/PageTitle'

const TeamPage = () => {
  useDocumentTitle("TRoo Gardener | Team Page")
  return (
   <Layout>
    <HeroGlobal title="Meet Our Professional Gardener"/>
    <Teams isTeam/>
   </Layout>
  )
}

export default TeamPage