import React from 'react'
import Layout from '../../Components/Layout'
import img404 from "../../images/error404.png"
import { Link } from 'react-router-dom'
import HeroGlobal from '../../Components/HeroGlobal'
import useDocumentTitle from '../../Hooks/PageTitle'


const Error404Page = () => {
  useDocumentTitle("TRoo Gardener | Error-404 page")
  return (
    <Layout>
      <HeroGlobal title="Error-404"/>
        <div className='py-12 lg:py-24'>
        <div className="main_container1 flex justify-center">
        <div className="flex flex-col text-center gap-9">
          <div className="">
            <img
              className="lg:max-w-[400px]   mx-auto"
              src={img404}
              alt={img404}
            />
          </div>
          <h2 className='p-3 rounded bg-troo-primary'>Ouch, Something Went Wrong!</h2>
          <h3 className="max-w-[600px] text-center m-auto font-Infont">
          Sorry We Can’t Find The page You Are Looking For
          </h3>
          <h3>Click on Button to Back Homepage</h3>
          <div className='flex sm:flex-row flex-col justify-center gap-5'>
          <div>
          <Link
              to="/"
              className="btn1 hover:bg-troo-primary bg-troo-secondary text-troo-white "
            >
           Back to Homepage <spna>+</spna>
            </Link>
          </div>
           <div>
           <Link
              to="/"
              className="btn1 hover:bg-troo-primary bg-troo-black text-troo-white "
            >
            Contact Us <span>+</span>
            </Link>
           </div>
          </div>
        </div>
      </div>
        </div>
    </Layout>
  )
}

export default Error404Page