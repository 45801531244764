import React, { useEffect, useState } from "react";
import Highlights from "./Highlights";
import Heading from "./Heading";
import { BlogData } from "../Data/BlogData";
import { Link } from "react-router-dom";

const Blogs = ({ isBlog }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isBlog) {
      setData(BlogData.slice(0, 4));
    } else {
      setData(BlogData);
    }
  }, []);
  return (
    <div className="py-12 lg:py-24 ">
      <div className="main_container1">
        {!isBlog && <Highlights />}
        {!isBlog && (
          <div className="mb-12">
            <Heading
              title={
                <>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority <br />
                  have suffered alteration in some form, by injected humour.
                </>
              }
              heading={<>Stay Uptodate with Our News & Blogs</>}
              isCenter
            />
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {data.map((e, i) => (
            <div key={i}  className="p-5 shadow-lg border hover:border-troo-secondary transition-all duration-500 ease-in-out flex flex-col sm:flex-row gap-5 w-full bg-troo-white group">
              <div className="sm:w-[40%] flex justify-center">
                <img src={e.img} alt={e.name} />
              </div>
              <div className="flex flex-col gap-5 ">
                <p>
                  <spn className="font-bold text-troo-secondary">
                    Business -
                  </spn>{" "}
                  {e.date}
                </p>
                <h3 className="border-b pb-4">{e.name}</h3>
                <p>{e.sub}</p>
                <Link  to={`/our-blogs  /${e.slug}`} className="underline font-bold text-base font-Barlow group-hover:text-troo-secondary">
                  Read Full Article
                </Link>
              </div>
            </div>
          ))}
        </div>
        {!isBlog && (
          <div className="mt-12 text-center">
            <Link
              to="/our-blogs"
              className="btn1 border border-troo-black hover:bg-troo-secondary hover:text-troo-white "
            >
              View All Blogs <span>+</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
