import React, { useState } from "react";
import Heading from "./Heading";
import faqImg from "../images/faqImg.png";
import { FAQData } from "../Data/FAQData";
import { FiPlus, FiMinus  } from "react-icons/fi";
import { Link } from "react-router-dom";

const FAQs = () => {
  const [activeAccordion, setActiveAccordion] = useState(0);

  const toggleAccordion = (index) => {
    setActiveAccordion((prevIndex) => (prevIndex === index ? -1 : index));
  };
  return (
    <div className="pt-12 lg:pt-56 pb-12 lg:pb-24 bg-troo-tertiary">
      <div className="main_container1 flex flex-col lg:flex-row gap-8">
        <div className="lg:w-[37%]">
          <div className="mb-6">
            <Heading
              title={
                <>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </>
              }
              heading={
                <>
                  Some Frequently <br />
                  Asked Question
                </>
              }
            />
          </div>
          <div className="relative flex justify-center rounded">
            <img className="w-full" src={faqImg} alt="faqImg" />
            <div className="absolute bottom-0 py-8 bg-troo-secondary rounded-tr-full border-t-8 border-troo-primary w-full text-troo-white">
              <h4 className="text-center font-normal flex flex-col">
                Have you more questions in mind?{" "}
                <Link className="font-bold text-troo-primary">
                  View more questions
                </Link>
              </h4>
            </div>
          </div>
        </div>
        <div className="lg:w-[63%]">
          <div className="hs-accordion-group flex flex-col gap-4">
            {FAQData.slice(0, 6).map((e, i) => (
              <div
                className="hs-accordion px-5 py-4 border border-troo-black border-opacity-20 rounded"
                key={`accordion-${i}`}
                id={`hs-basic-with-title-and-arrow-stretched-heading-${i}`}
              >
                <button
                  className="hs-accordion-toggle inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:text-gray-400"
                  onClick={() => toggleAccordion(i)}
                  aria-controls={`hs-basic-with-title-and-arrow-stretched-collapse-${i}`}
                >
                  <h4>{e.name}</h4>
                  {activeAccordion === i ? (
                    <FiMinus  className=" bg-troo-secondary w-8 h-8 p-2 rounded text-troo-white" />
                  ) : (
                    <FiPlus  className="w-8 h-8 p-2 rounded bg-troo-secondary bg-opacity-10" />
                  )}
                </button>
                <div
                  id={`hs-basic-with-title-and-arrow-stretched-collapse-${i}`}
                  className={`hs-accordion-content w-full overflow-hidden transition-height duration-300 ease-in-out ${
                    activeAccordion === i ? "max-h-96" : "max-h-0"
                  }`}
                  aria-labelledby={`hs-basic-with-title-and-arrow-stretched-heading-${i}`}
                >
                  <p className="mt-3">{e.para}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
