import React from "react";

const Input = ({ placeholder, isservice, type, icon, ...rest }) => {
  return (
    <div className={`flex items-center w-full px-5 py-4 justify-between border hover:border-troo-secondary hover:border-opacity-50 border-troo-black border-opacity-20 rounded `}>
      <input
        type={type}
        placeholder={placeholder}
        {...rest}
        className={` bg-transparent outline-none w-full `}
      />
      <div className={` text-xl cursor-pointer`}>{icon}</div>
    </div>
  );
};

export default Input;
