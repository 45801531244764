import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import Testimonials from '../../Components/Testimonials'
import useDocumentTitle from '../../Hooks/PageTitle'

const TesimonialPage = () => {
  useDocumentTitle("TRoo Gardener | Testimonial Page")
  return (
    <Layout>
        <HeroGlobal title="Awesome Clients Feedback"/>
        <Testimonials isTestimonial/>
    </Layout>
  )
}

export default TesimonialPage