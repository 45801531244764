import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import FAQ2 from '../../Components/FAQ2'
import useDocumentTitle from '../../Hooks/PageTitle'

const FaqPage = () => {
  useDocumentTitle("TRoo Gardener | FAQ Page")
  return (
    <Layout>
        <HeroGlobal title="Frequently Asked Questions"/>
        <FAQ2/>
    </Layout>
  )
}

export default FaqPage