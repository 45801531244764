import React from "react";
import serviceDetailsImg from "../images/serviceDetailsImg.png";
import { FaRegCircleCheck } from "react-icons/fa6";
import serviceDetails1 from "../images/serviceDetails1.png";
import serviceDetails2 from "../images/serviceDetails2.png";
import { BsCheckLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ServiceData } from "../Data/SeerviceData";
import { HiOutlineArrowUpRight } from "react-icons/hi2";
import serviceside1 from "../images/serviceside1.png"

const ServiceDetails = () => {
  return (
    <div className="py-12 lg:py-24 w-full">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row h-full items-start gap-6">
          <div className="w-full lg:w-[75%] flex flex-col items-start gap-5">
            <div>
              <img className="w-full" src={serviceDetailsImg} alt="seviceImg" />
            </div>
            <h3>Discription</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printin and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
              typesetting industry.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printin and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley
            </p>
            <h3>Your title goes here</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printin and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
            <div className="flex gap-5 items-center bg-troo-secondary p-5 rounded">
              <div className="bg-troo-primary p-3  flex justify-center items-center text-troo-white rounded-full">
                <FaRegCircleCheck className="w-5 h-5" />
              </div>
              <div className="flex flex-col gap-5 text-troo-white">
                <h4>Spring & Fall Cleanup</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry simply
                  dummy text of the printing and{" "}
                </p>
              </div>
            </div>
            <div className="flex sm:flex-row flex-col gap-5">
              <img src={serviceDetails1} alt="details1" />
              <img src={serviceDetails2} alt="details2" />
            </div>
            <h3>Your title goes here</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printin and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
            <h3>Your title goes here</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
              <p className="flex items-center gap-2">
                <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <p className="flex items-center gap-2">
                <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <p className="flex items-center gap-2">
                <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                Lorem ipsum dolor sit amet.
              </p>
              <p className="flex items-center gap-2">
                <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                Lorem ipsum dolor sit amet.
              </p>
              <p className="flex items-center gap-2">
                <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                Lorem ipsum dolor sit amet, consectetur.
              </p>
              <p className="flex items-center gap-2">
                <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                Lorem ipsum dolor sit amet, consectetur.
              </p>
            </div>
          </div>
          <div className="w-full md:sticky top-44 lg:w-[25%]">
            <div className="flex flex-col items-start w-full  bg-troo-tertiary mb-10">
              <h4 className="relative bg-troo-secondary text-troo-white p-5 w-full">
                Other Services
              </h4>
              <div className="flex flex-col w-full">
                {ServiceData.slice(0, 6).map((e, i) => (
                    <Link
                      key={i}
                      className="p-5 w-full border flex justify-between gap-3 items-center group"
                    >
                      {e.name}
                      <div className="w-8 h-8 bg-troo-secondary bg-opacity-10 p-2 rounded-full flex justify-center items-center group-hover:text-troo-white group-hover:bg-opacity-100 transition-all duration-500 ease-in-out font-semibold">
                      <HiOutlineArrowUpRight />
                      </div>
                    </Link>
                ))}
              </div>
            </div>
            <div className="relative w-full">
                <img className="w-full" src={serviceside1} alt="serviceSideImg" />
                <div className="absolute bottom-0 py-8 bg-troo-secondary rounded-tr-full border-t-8 border-troo-primary w-full text-troo-white">
              <h4 className="text-center font-normal flex flex-col">
                <Link className="font-bold text-troo-primary">
                Book an Appointment
                </Link>
              </h4>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
