import React, { useState } from "react";
import { FAQData } from "../Data/FAQData";
import { FiMinus, FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import Highlights from "./Highlights";

const Accordion = ({ item, index, isActive, toggleAccordion }) => (
  <div
    className="hs-accordion px-5 py-4 border border-troo-black border-opacity-20 rounded bg-troo-tertiary"
    key={`accordion-${index}`}
    id={`hs-basic-with-title-and-arrow-stretched-heading-${index}`}
  >
    <button
      className="hs-accordion-toggle inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:text-gray-400"
      onClick={() => toggleAccordion(index)}
      aria-controls={`hs-basic-with-title-and-arrow-stretched-collapse-${index}`}
    >
      <h4>{item.name}</h4>
      {isActive ? (
        <FiMinus className="bg-troo-secondary w-8 h-8 p-2 rounded text-troo-white" />
      ) : (
        <FiPlus className="w-8 h-8 p-2 rounded bg-troo-secondary bg-opacity-10" />
      )}
    </button>
    <div
      id={`hs-basic-with-title-and-arrow-stretched-collapse-${index}`}
      className={`hs-accordion-content w-full overflow-hidden transition-height duration-300 ease-in-out ${
        isActive ? "max-h-96" : "max-h-0"
      }`}
      aria-labelledby={`hs-basic-with-title-and-arrow-stretched-heading-${index}`}
    >
      <p className="mt-3">{item.para}</p>
    </div>
  </div>
);

const FAQ2 = () => {
  const [activeAccordion1, setActiveAccordion1] = useState(0);
  const [activeAccordion2, setActiveAccordion2] = useState(0);

  const toggleAccordion = (index, setFunction) => {
    setFunction((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="flex flex-col gap-5">
            {FAQData.slice(0, 6).map((item, index) => (
              <Accordion
                key={index}
                item={item}
                index={index}
                isActive={activeAccordion1 === index}
                toggleAccordion={() =>
                  toggleAccordion(index, setActiveAccordion1)
                }
              />
            ))}
          </div>
          <div className="flex flex-col gap-5">
            {FAQData.slice(6, 12).map((item, index) => (
              <Accordion
                key={index + 6}
                item={item}
                index={index}
                isActive={activeAccordion2 === index}
                toggleAccordion={() =>
                  toggleAccordion(index, setActiveAccordion2)
                }
              />
            ))}
          </div>
        </div>
        <div className="mt-12 flex justify-center">
          <Link className="text-xl font-Barlow p-3 border border-dashed border-troo-black rounded">
            Did’t Find Your Answer ?
            <span className="font-bold text-troo-secondary">
              Contact With Us
            </span>
          </Link>
        </div>
      
       <Highlights isFaq/>
      
      </div>
    </div>
  );
};

export default FAQ2;
