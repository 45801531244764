import service1 from "../images/service1.png"
import service2 from "../images/service2.png"
import service3 from "../images/service3.png"
import service4 from "../images/service4.png"
import service5 from "../images/service5.png"
import service6 from "../images/service6.png"
import service7 from "../images/service7.png"
import service8 from "../images/service8.png"
import service9 from "../images/service9.png"

export const ServiceData = [
    {
        id :1,
        img : service1,
        name : "Home Gardening",
        slug :"home-gardening",
        para : "There are many variations of passages of Lorem Ipsum available,but the majority have suffered alteration in some form, by injected humour."
    },
    {
        id :2,
        img : service2,
        name : "Landscaping Service",
        slug :"landscaping-service",
        para : "There are many variations of passages of Lorem Ipsum available,but the majority have suffered alteration in some form, by injected humour."
    },
    {
        id :3,
        img : service3,
        name : "Garden Walls",
        slug :"garden-wall",
        para : "There are many variations of passages of Lorem Ipsum available,but the majority have suffered alteration in some form, by injected humour."
    },
    {
        id :4,
        img : service4,
        name : "Pathways & Patios",
        slug :"pathways-&-patios",
        para : "There are many variations of passages of Lorem Ipsum available,but the majority have suffered alteration in some form, by injected humour."
    },
    {
        id :5,
        img : service5,
        name : "Ponds & Water Features",
        slug :"ponds-&-water-features",
        para : "There are many variations of passages of Lorem Ipsum available,but the majority have suffered alteration in some form, by injected humour."
    },
    {
        id :6,
        img : service6,
        name : "Garden Cleaning",
        slug :"garden-cleaning",
        para : "There are many variations of passages of Lorem Ipsum available,but the majority have suffered alteration in some form, by injected humour."
    },
    {
        id :7,
        img : service7,
        name : "Holiday Decoration",
        slug :"holiday-decoration",
        para : "There are many variations of passages of Lorem Ipsum available,but the majority have suffered alteration in some form, by injected humour."
    },  {
        id :8,
        img : service8,
        name : "Trees Paintings",
        slug :"trees-paintings",
        para : "There are many variations of passages of Lorem Ipsum available,but the majority have suffered alteration in some form, by injected humour."
    },
    {
        id :9,
        img : service9,
        name : "Tree-trimming & Removal",
        slug :"tree-trimming-&-removal",
        para : "There are many variations of passages of Lorem Ipsum available,but the majority have suffered alteration in some form, by injected humour."
    },
]