import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FiChevronRight } from "react-icons/fi";
import { FiPlay } from "react-icons/fi";

const HeroSection = () => {
  return (
    <div className=" top-0 w-full">
      <div className="bg-hero-img bg-no-repeat bg-cover w-full  relative">
        <div className="main_container1 pt-36 lg:pt-64   pb-20 lg:pb-40 flex flex-col md:flex-row text-troo-white gap-10">
          <div className="md:w-[70%] flex flex-col gap-12">
            <motion.h1
              initial={{ scale: 0.7 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 1, type: "spring", stiffness: 100 }}
            >
              <span className="font-extrabold">Your garden,</span>
              <span className="font-light">
                if you can dream it, we can design and build it!
              </span>
            </motion.h1>
            <p className="border-l-[3px] max-w-[650px] pl-5 border-troo-white">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable.
            </p>
            <div className="flex flex-col sm:flex-row gap-10 text-troo-black">
              <div>
                <Link
                  to="/about-us"
                  className="btn1 bg-troo-primary  hover:bg-troo-white "
                >
                  Explore Services <FiChevronRight />
                </Link>
              </div>
              <div>
                <Link
                  to="/about-us"
                  className="btn1 bg-troo-white hover:bg-troo-primary"
                >
                  Learm About Us
                </Link>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <button
              type="button"
              data-hs-overlay="#hs-vertically-centered-modal"
              className="relative flex items-center justify-center bg-troo-primary text-troo-primaryDark rounded-full p-5 text-3xl z-[1] "
            >
              <div className="absolute w-full h-full bg-troo-primary rounded-full bg-opacity-40 scale-125 -z-[1] animate-pulse animate-infinite duration-100 delay-75"></div>
              <div className="absolute w-full h-full bg-troo-primary rounded-full bg-opacity-30 scale-150 -z-[1] animate-pulse animate-infinite duration-300 delay-200"></div>
              <div className="absolute w-full h-full bg-troo-primary rounded-full bg-opacity-20 scale-[1.75] -z-[1] animate-pulse animate-infinite duration-700 delay-500"></div>
              <FiPlay className="text-troo-white" />
            </button>
            <div
              id="hs-vertically-centered-modal"
              className="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[60] overflow-x-hidden overflow-y-auto pointer-events-none"
            >
              <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
                <form
                  method="dialog"
                  className="w-full flex flex-col bg-white shadow-sm rounded-xl pointer-events-auto dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]"
                >
                  <iframe
                    width="100%"
                    height="450"
                    src="https://www.youtube.com/embed/DUfXdcpEfMs"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden  sm:flex flex-wrap justify-between">
          <div className="bg-troo-secondary py-8 pr-6 text-troo-white inline-flex gap-3 items-center">
            <div className="w-24 h-[1px] bg-troo-white"></div>
            <Link className="hover:text-troo-primary">Social Media:</Link>
            <Link className="hover:text-troo-primary">Facebook</Link>
            <Link className="hover:text-troo-primary">Twitter</Link>
            <Link className="hover:text-troo-primary">Linkedin</Link>
            <Link className="hover:text-troo-primary">Instagram</Link>
          </div>
          <div className="font-outline-2 font-Barlow font-black text-transparent text-5xl xl:text-9xl pr-6">
            GARENING SERVICES
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
