import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import ServiceDetails from '../../Components/ServiceDetails'
import Testimonials from '../../Components/Testimonials'
import useDocumentTitle from '../../Hooks/PageTitle'

const ServiceDetailsPage = () => {
  useDocumentTitle("TRoo Gardener | Service Details")
  return (
   <Layout>
    <HeroGlobal title="Home Gardening"/>
    <ServiceDetails/>
    <Testimonials/>
   </Layout>
  )
}

export default ServiceDetailsPage