import React from "react";
import Heading from "./Heading";
import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import hightImg from "../images/highlightImg.png"

const Highlights = ({isFaq}) => {
  return (
    <div className={`bg-highlight-bg bg-no-repeat bg-troo-black w-full h-full grid grid-cols-1 lg:grid-cols-2  ${isFaq?" mt-24":"mb-24"}`}>
      <div className="pl-10 py-10 flex flex-col gap-5">
        <p className="font-bold text-troo-white">
          Quality Roofing Services Provider
        </p>
        <Heading
          heading={
            <>
              Need Any Gardening Services? <br />
              We Are Here For You.
            </>
          }
          title={
            <>
              There are many variations of passages of Lorem Ipsum available,
              <br /> but the majority have suffered alteration in some form.
            </>
          }
          textLight
        />
        <div>
          <Link
            to="/contact-us"
            className="btn1 bg-troo-white border border-dashed border-troo-white  hover:bg-troo-secondary hover:text-troo-white "
          >
            Contact With Us <FiPlus />
          </Link>
        </div>
      </div>
      <div>
<img className="w-full" src={hightImg} alt="highlightImg" />
      </div>
    </div>
  );
};

export default Highlights;
