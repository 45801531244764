import React, { useEffect, useState } from "react";
import teamDetailsimg from "../images/teamDetails.png";
import chat from "../images/chat.svg";
import { Link, useParams } from "react-router-dom";
import { TeamData } from "../Data/TeamData";

const TeamDetails = () => {
  const { slug } = useParams();
  const [teamDetails, setTeamDetails] = useState();
  useEffect(() => {
    const filteredData = TeamData.filter((data) => data.slug === slug);
    setTeamDetails(filteredData[0]);
  }, [slug]);
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="lg:w-[40%] relative flex justify-center">
            <img className="w-full" src={teamDetails?.img || teamDetailsimg} alt="teamDetailsImg" />
            <div className="absolute bottom-5 w-[80%] bg-troo-black p-5 flex justify-between items-center gap-5">
              <div className="flex flex-col gap-2 text-troo-white">
                <p>Contact Information</p>
                <h4>alexabrethfinix@email.com</h4>
                <h4>+44 123 456 7890</h4>
              </div>
              <div>
                <img src={chat} alt="chatIcon" />
              </div>
            </div>
          </div>
          <div className="lg:w-[60%] flex flex-col gap-6">
            <div className="pb-5 border-b border-troo-black border-opacity-20 flex sm:flex-row flex-col justify-between items-center gap-5 ">
              <div className="flex flex-col gap-3">
                <h2>{teamDetails?.name || "Alexa Brethfinix"}</h2>
                <h3>{teamDetails?.sub || "SEO and Founder"}</h3>
              </div>
              <div className="flex justify-end items-center">
                <div className="bg-troo-secondary flex gap-3 p-3 text-troo-white rounded">
                  <Link className="hover:text-troo-primary font-semibold font-Barlow text-base">
                    FB
                  </Link>
                  <Link className="hover:text-troo-primary font-semibold font-Barlow text-base">
                    TW
                  </Link>
                  <Link className="hover:text-troo-primary font-semibold font-Barlow text-base">
                    LD
                  </Link>
                  <Link className="hover:text-troo-primary font-semibold font-Barlow text-base">
                    IG
                  </Link>
                </div>
              </div>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable.There are many variations of passages of Lorem
              Ipsum available, but the majority have suffered alteration in some
              form, by injected humour, or randomised words which don't look
              even slightly believable.There are many variations of passages of
              Lorem Ipsum available, but the majority have suffered alteration
              in some form, by injected humour, or randomised words which don't
              look even slightly believable.There are many variations of
              passages of Lorem Ipsum available, but the majority have suffered
              alteration in some form, by injected humour.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable.There are many variations of passages of Lorem
              Ipsum available, but the majority have suffered alteration in some
              form, by injected humour, or randomised words which don't look
              even slightly believable.There are many variations.
            </p>
            <h3>Experience & Activities</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              sunt eiusmod tempor incididunt labore dolore magna aliqua enim
              minim veniam quis nostrud exercitation ullamco laboris nisi ut
              aliquip isit dx ea commodo consequat.Lorem ipsum dolor sit amet,
              consectetur adipisicing elit, sed do sunt eiusmod tempfshtor
              incididunt labore dolore magna aliqua enim minim veniam. Lorem
              ipsum dolor sit amet, consectetur adipisicing elit.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;
