import React from 'react'
import sweeperman from "../images/sweeperMan.png";
import { FetureData } from "../Data/FetureData";

const Fetures = () => {
  return (
    <div className="main_container1 w-full relative mb-12 lg:mb-20 pt-24 ">
    <div className="bg-troo-secondary flex flex-col lg:flex-row">
      <img src={sweeperman} alt="sweeperman" />
      <div className="grid grid-cols-1 md:grid-cols-2 p-7 gap-7">
        {FetureData.map((e, i) => (
          <div className="flex justify-center items-center bg-troo-white bg-opacity-5" key={i}>
            <div className="flex h-full w-[30%] px-3 lg:px-6 bg-troo-white bg-opacity-5">
              <img className=" w-full " src={e.img} alt="feture icons" />
            </div>
            <div className="p-5 w-[70%] flex flex-col gap-3 text-troo-white">
              <h4>{e.name}</h4>
              <p>{e.para}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  )
}

export default Fetures