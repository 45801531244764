import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import Blogs from '../../Components/Blogs'
import useDocumentTitle from '../../Hooks/PageTitle'

const BlogPage = () => {
  useDocumentTitle("TRoo Gardener | Blogs Page")
  return (
    <Layout>
        <HeroGlobal title="TRoo Gardening Blogs & News"/>
        <Blogs isBlog/>
    </Layout>
  )
}

export default BlogPage