import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import CaaseStudy from '../../Components/CaaseStudy'
import useDocumentTitle from '../../Hooks/PageTitle'



const CaseStudyPage = () => {
  useDocumentTitle("TRoo Gardener | Cast Study Page")
  return (
    <Layout>
        <HeroGlobal title="Case Studies"/>
       <CaaseStudy/>
    </Layout>
  )
}

export default CaseStudyPage