import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineArrowUpRight } from "react-icons/hi2";
import { BsCheckLg } from "react-icons/bs";
import { FaRegCircleCheck } from "react-icons/fa6";
import serviceDetailsImg from "../images/serviceDetailsImg.png";
import serviceDetails1 from "../images/serviceDetails1.png";
import serviceDetails2 from "../images/serviceDetails2.png";
import serviceside1 from "../images/serviceside1.png";
import { ServiceData } from "../Data/SeerviceData";
import blogDetails1 from "../images/detailsBlog1.png";
import blogDetails2 from "../images/detailsBlog2.png";
import Input from "./Input";

const BlogDetails = () => {
  return (
    <div className="py-12 lg:py-24 w-full">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row h-full items-start gap-6">
          <div className="w-full lg:w-[75%] flex flex-col items-start gap-5">
            <div>
              <img className="w-full" src={serviceDetailsImg} alt="seviceImg" />
            </div>
            <h3>Discription</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printin and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
              typesetting industry.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printin and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley
            </p>
            <h3>Your title goes here</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printin and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
            <div className="flex gap-5 items-center bg-troo-secondary p-5 rounded">
              <div className="flex md:flex-row flex-col gap-5">
                <div className="">
                  <FaRegCircleCheck className="w-12 h-12 bg-troo-primary p-3  flex justify-center items-center text-troo-white rounded-full" />
                </div>
                <div className="flex flex-col gap-5 text-troo-white">
                  <h4>Spring & Fall Cleanup</h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry
                    simply dummy text of the printing and{" "}
                  </p>
                </div>
                <div className="">
                  <FaRegCircleCheck className="w-12 h-12 bg-troo-primary p-3  flex justify-center items-center text-troo-white rounded-full" />
                </div>
                <div className="flex flex-col gap-5 text-troo-white">
                  <h4>Spring & Fall Cleanup</h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry
                    simply dummy text of the printing and{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex sm:flex-row flex-col gap-5">
              <img src={serviceDetails1} alt="details1" />
              <img src={serviceDetails2} alt="details2" />
            </div>
            <h3>Your title goes here</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printin and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
            <h3>Your title goes here</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
              <p className="flex items-center gap-2">
                <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <p className="flex items-center gap-2">
                <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <p className="flex items-center gap-2">
                <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                Lorem ipsum dolor sit amet.
              </p>
              <p className="flex items-center gap-2">
                <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                Lorem ipsum dolor sit amet.
              </p>
              <p className="flex items-center gap-2">
                <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                Lorem ipsum dolor sit amet, consectetur.
              </p>
              <p className="flex items-center gap-2">
                <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-secondary rounded-[50%] text-troo-white " />
                Lorem ipsum dolor sit amet, consectetur.
              </p>
            </div>

            <div className="flex items-center flex-wrap gap-3 p-3 mt-8 w-full border-troo-black border-opacity-20 border-y">
              <p>Share This Article:</p>
              <p className="font-semibold hover:text-troo-primary transition-all">
                Facebook
              </p>
              <p className="font-semibold hover:text-troo-primary transition-all">
                Twitter
              </p>
              <p className="font-semibold hover:text-troo-primary transition-all">
                Instagram
              </p>
              <p className="font-semibold hover:text-troo-primary transition-all">
                Linkedin
              </p>
            </div>
            <h3 className="mt-7 font-medium">Comments (0)</h3>
            <h4 className="font-medium">Leave A Comment</h4>
            <form className="w-full  flex flex-col items-start gap-14">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
                <Input placeholder="Full Name"  />
                <Input placeholder="Email Adress" />
                <Input placeholder="Phone No."  />
                <Input placeholder="Company Name" />
                <div className="flex md:col-span-2 items-start w-full rounded px-5 py-3 justify-between border border-troo-whitefade2">
                  <textarea
                    rows={5}
                    placeholder="Message"
                    className="bg-transparent outline-none w-full"
                  />

                </div>
                <div className="flex items-center w-full md:col-span-2 gap-3">
                  <input type="checkbox" />
                  <label>
                    Save my name, email, and website in this browser for the
                    next time I comment.
                  </label>
                </div>
              </div>
              <button className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-black">
                Send a Message
              </button>
            </form>
          </div>
          <div className="w-full md:sticky top-44 lg:w-[25%]">
            <div className="flex flex-col items-start w-full  bg-troo-tertiary mb-10 border">
              <h4 className="relative bg-troo-secondary text-troo-white p-5 w-full">
                Blog Detail
              </h4>
              <div className="p-5 flex flex-col gap-3">
                <p>
                  Published: <span className="font-bold ">22 Jan, 2023</span>
                </p>
                <p>
                  Writen By: <span className="font-bold ">John Johnson</span>
                </p>
                <p>
                  Category: <span className="font-bold ">Spa and Therapy</span>
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start w-full  bg-troo-tertiary mb-10">
              <h4 className="relative bg-troo-secondary text-troo-white p-5 w-full">
                Other Services
              </h4>
              <div className="flex flex-col w-full">
                {ServiceData.slice(0, 6).map((e, i) => (
                  <Link
                    key={i}
                    className="p-5 w-full border flex justify-between gap-3 items-center group"
                  >
                    {e.name}
                    <div className="w-8 h-8 bg-troo-secondary bg-opacity-10 p-2 rounded-full flex justify-center items-center group-hover:text-troo-white group-hover:bg-opacity-100 transition-all duration-500 ease-in-out font-semibold">
                      <HiOutlineArrowUpRight />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div className="mb-10 border ">
              <h4 className="relative bg-troo-secondary text-troo-white p-5 w-full">
                Tags
              </h4>
              <div className="bg-troo-tertiary flex p-5 gap-2 flex-wrap">
                <p className="p-2 hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-300 rounded">
                  Gardening
                </p>
                <p className="p-2 hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-300 rounded">
                  Landscape
                </p>
                <p className="p-2 hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-300 rounded">
                  Plants
                </p>
                <p className="p-2 hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-300 rounded">
                  Tree
                </p>
                <p className="p-2 hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-300 rounded">
                  Trimming
                </p>
                <p className="p-2 hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-300 rounded">
                  Watering
                </p>
                <p className="p-2 hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-300 rounded">
                  Yard
                </p>
                <p className="p-2 hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-300 rounded">
                  Garden Maintenance
                </p>
                <p className="p-2 hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-300 rounded">
                  Yard Cleaning
                </p>
              </div>
            </div>
            <div className="mb-10 border">
              <h4 className="relative bg-troo-secondary text-troo-white p-5 w-full">
                Recent News
              </h4>
              <div className="flex flex-col gap-5 bg-troo-tertiary">
                <div className="flex flex-col text-center gap-5 p-5 border-b">
                  <img src={blogDetails1} alt="blogDetails1" />
                  <h4>The Urban Farming Workshop for Kids</h4>
                  <Link className="text-base font-bold font-Barlow underline">
                    Read Full Article
                  </Link>
                </div>
                <div className="flex flex-col text-center gap-5 p-5">
                  <img src={blogDetails2} alt="blogDetails2" />
                  <h4>Ocean sunfish, tiger shark cat shark flat loach</h4>
                  <Link className="text-base font-bold font-Barlow underline">
                    Read Full Article
                  </Link>
                </div>
              </div>
            </div>
            <div className="relative w-full">
              <img className="w-full" src={serviceside1} alt="serviceSideImg" />
              <div className="absolute bottom-0 py-8 bg-troo-secondary rounded-tr-full border-t-8 border-troo-primary w-full text-troo-white">
                <h4 className="text-center font-normal flex flex-col">
                  <Link className="font-bold text-troo-primary">
                    Book an Appointment
                  </Link>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
