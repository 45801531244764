import portfolio1 from "../images/portfolio1.png"
import portfolio2 from "../images/portfolio2.png"
import portfolio3 from "../images/portfolio3.png"
import portfolio4 from "../images/portfolio4.png"
import portfolio5 from "../images/portfolio5.png"
import portfolio6 from "../images/portfolio6.png"
import portfolio7 from "../images/portfolio7.png"
import portfolio8 from "../images/portfolio8.png"
import portfolio9 from "../images/portfolio9.png"
import portfolio10 from "../images/portfolio10.png"
import portfolio11 from "../images/portfolio11.png"
import portfolio12 from "../images/portfolio12.png"



export const PortfolioData = [
    {
        id : 1,
        img : portfolio1,
        name :"Bird Wave Bridge Project",
        slug : "bird-wave-bridge-project",
        sub : "Home Garening"
    },
    {
        id : 2,
        img : portfolio2,
        name :"Landscaping for Restaurant",
        slug : "landscaping-for-restaurant",
        sub : "Restauran Garening"
    },
    {
        id : 3,
        img : portfolio3,
        name :"Municipal Garden Water ",
        slug : "municipal-garden-water",
        sub : "Garden Watering"
    },
    {
        id : 4,
        img : portfolio4,
        name :"Lawn Renovation for Home",
        slug : "lawn-revalution-for-home",
        sub : "Lawn Renovation"
    },
    {
        id : 5,
        img : portfolio5,
        name :"Austin Central Library",
        slug : "austin-central-library",
        sub : "Development"
    },
    {
        id : 6,
        img : portfolio6,
        name :"Agnes Scott College",
        slug : "agnes-scott-college",
        sub : "Design"
    },
    {
        id : 7,
        img : portfolio7,
        name :"Georgia Tech AMC Basketball Arena",
        slug : "georgia-tech-amc-basketball-arena",
        sub : "Water Management"
    },
    {
        id : 8,
        img : portfolio8,
        name :"Harmony Grove Village",
        slug : "harmony-grave-village",
        sub : "Tree Care"
    },
    {
        id : 9,
        img : portfolio9,
        name :"Independence National Historical Park",
        slug : "independence-national-historical-park",
        sub : "Restauran Garening"
    },
    {
        id : 10,
        img : portfolio10,
        name :"Letterman Digital Arts Center",
        slug : "letterman-degital-arts-center",
        sub : "Maintenance"
    },
    {
        id : 11,
        img : portfolio11,
        name :"Miami Beach Soundscape",
        slug : "miami-beach-soundscape",
        sub : "Development"
    },
    {
        id : 12,
        img : portfolio12,
        name :"National Mall",
        slug : "national-mall",
        sub : "Water Management"
    },

]