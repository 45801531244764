import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import counter1 from "../images/counter1.svg";
import counter2 from "../images/counter2.svg";
import counter3 from "../images/counter3.svg";

const Counter = () => {
  const [counter, setCounter] = useState(false);
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 bg-troo-black relative lg:-mb-28">
      <div className="transition-all ease-in-out duration-500 group rounded-s">
        <div className="flex justify-center gap-4 items-center p-5 md:p-10 lg:p-14 relative before:absolute before:w-[1px] before:h-[100px] before:bg-troo-white before:right-0 before:bottom-0 before:top-0 before:opacity-50 before:m-auto before:transition-all before:ease-in-out before:duration-500 hover:before:h-full text-troo-white">
          <div>
            <img src={counter1} alt="counter1" />
          </div>
          <div className="flex flex-col gap-4">
            <h2 className="lg:text-6xl text-3xl">
              <ScrollTrigger
                onEnter={() => setCounter(true)}
                onExit={() => setCounter(false)}
              >
                {counter && (
                  <CountUp start={0} end={30} duration={3} delay={0} />
                )}
                <strong>+</strong>
              </ScrollTrigger>
            </h2>
            <h4 className="">Year experience</h4>
          </div>
        </div>
      </div>

      <div className="transition-all ease-in-out duration-500 group">
        <div className="flex justify-center gap-4 items-center p-5 md:p-10 lg:p-14 relative before:absolute before:w-[1px] before:h-[100px] before:bg-troo-white before:right-0 before:bottom-0 before:top-0 before:opacity-50 before:m-auto before:transition-all before:ease-in-out before:duration-500 hover:before:h-full text-troo-white">
          <div>
            <img src={counter2} alt="counter2" />
          </div>
          <div className="flex flex-col gap-4">
            <h2 className=" lg:text-6xl text-3xl ">
              <ScrollTrigger
                onEnter={() => setCounter(true)}
                onExit={() => setCounter(false)}
              >
                {counter && (
                  <CountUp start={50} end={100} duration={3} delay={0} />
                )}
                <strong>%</strong>
              </ScrollTrigger>
            </h2>
            <h4 className="">Clients setisfection</h4>
          </div>
        </div>
      </div>
      <div className=" transition-all ease-in-out duration-500 group rounded-e">
        <div className="flex justify-center gap-4 items-center p-5 md:p-10 lg:p-14 relative text-troo-white">
      <div>
            <img src={counter3} alt="counter3" />
          </div>
       <div className="flex flex-col gap-4">
       <h2 className=" lg:text-6xl text-3xl">
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && <CountUp start={5} end={55} duration={3} delay={0} />}
              <strong>+</strong>
            </ScrollTrigger>
          </h2>
          <h4 className="">Projects Complete</h4>
       </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
