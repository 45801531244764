import React from 'react'
import Layout from '../Components/Layout'
import HeroGlobal from '../Components/HeroGlobal'
import About from '../Components/About'
import Portfolio from '../Components/Portfolio'
import Teams from '../Components/Teams'
import Testimonials from '../Components/Testimonials'
import OurWork from '../Components/OurWork'
import Brands from '../Components/Brands'
import useDocumentTitle from '../Hooks/PageTitle'

const AboutPage = () => {
  useDocumentTitle("TRoo Gardener | About Page")
  return (
    <Layout>
        <HeroGlobal title="About us"/>
        <About/>
        <Portfolio/>
        <Teams/>
        <Testimonials/>
        <OurWork/>
        <Brands isAbout/>
    </Layout>
  )
}

export default AboutPage