import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TeamData } from "../Data/TeamData";

const Teams = ({ isTeam }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isTeam) {
      setData(TeamData.slice(0, 3));
    } else {
      setData(TeamData);
    }
  }, []);
  return (
    <div
      className={` ${
        isTeam
          ? "py-12 lg:py-24"
          : "pt-12 lg:pt-44 pb-12 lg:pb-24 bg-team-Bg bg-cover bg-no-repeat w-full h-full bg-troo-tertiary"
      }`}
    >
      <div className="main_container1">
        {!isTeam && (
          <div className="mb-12 flex flex-col lg:flex-row gap-8 justify-between">
            <div className="flex flex-col w-full">
              <h2>Our Expert</h2>
              <h2>Gardener Team</h2>
            </div>
            <div className="flex flex-col gap-5">
              <h4 className="font-medium">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour.
              </h4>
              <Link to="/our-team"
               className="font-Barlow text-xl font-bold underline">
                View All Gardner
              </Link>
            </div>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
          {data.map((e, i) => (
            <div>
              <Link
              to={`/our-team/${e.slug}`}
                key={i}
                className="border group flex flex-col gap-8 px-6 lg:px-12 py-8 items-center justify-center hover:bg-troo-secondary bg-troo-tertiary hover:text-troo-white transition-all duration-500 ease-in-out group"
              >
                <div className="flex flex-col items-center gap-3">
                  <h3>{e.name}</h3>
                  <p>{e.sub}</p>
                </div>
                <div className="relative flex justify-center items-center before:content-[''] before:bg-troo-black before:bg-opacity-50 before:absolute before:top-0 before:w-full before:h-0 group-hover:before:h-full before:transition-all before:ease-in-out before:duration-500">
                  <img src={e.img} alt={e.name} />
                  <div className="flex gap-3 absolute scale-0 group-hover:scale-100 transition-all duration-700 ease-in-out">
                    {e.social.map((ele) => (
                      <Link className="font-semibold text-troo-white hover:text-troo-primary transition-all ease-in-out duration-300 text-lg font-Barlow">
                        {ele.Sname}
                      </Link>
                    ))}
                  </div>
                </div>
                <p className="text-center">{e.para}</p>
              </Link>
            </div>
          ))}
        </div>
        {isTeam && (
          <div className="mt-12 flex justify-center">
            <Link className="text-xl font-Barlow p-3 border border-dashed border-troo-black rounded">
              Want to Join Our Team?
              <span className="font-bold text-troo-secondary">
                {" "}
                Contact With Us
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Teams;
