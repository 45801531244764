import React from 'react'
import Layout from '../Components/Layout'
import HeroSection from '../Components/HeroSection'
import About from '../Components/About'
import Service from '../Components/Service'
import Portfolio from '../Components/Portfolio'
import Teams from '../Components/Teams'
import Testimonials from '../Components/Testimonials'
import OurWork from '../Components/OurWork'
import FAQs from '../Components/FAQs'
import Blogs from '../Components/Blogs'
import Brands from '../Components/Brands'
import useDocumentTitle from '../Hooks/PageTitle'

const Home = () => {
  useDocumentTitle("TRoo Gardener - React Js Template")
  return (
    <Layout>
       <HeroSection/> 
       <About isHome/>
       <Service isHome/>
       <Portfolio/>
       <Teams />
       <Testimonials/>
       <OurWork/>
       <FAQs/>
       <Blogs/>
       <Brands/>
    </Layout>
  )
}

export default Home