import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { BlogData } from "../Data/BlogData";
import { ServiceData } from "../Data/SeerviceData";
import { TeamData } from "../Data/TeamData";
import { PortfolioData } from "../Data/PortfolioData";

const HeroGlobal = ({title}) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();
  const pathName = location.pathname;
  const { slug } = useParams();
  const [serviceDetails, setServiceDetails] = useState();
  const allDataArr = [...ServiceData, ...BlogData,...TeamData,...PortfolioData];
  useEffect(() => {
    const filteredData = allDataArr.filter((data) => data.slug === slug);
    setServiceDetails(filteredData[0]);
  }, [slug]);
  console.log("serviceDetails", serviceDetails);

  useEffect(() => {
    const pathnameParts = location.pathname.split("/").filter((p) => !!p);
    const breadcrumbs = pathnameParts.map((part, i) => ({
      title: part,
      path: `/${pathnameParts.slice(0, i + 1).join("/")}`,
    }));
    setBreadcrumbs(breadcrumbs);
  }, [location]);
  return (
    <div className=" top-0 w-full">
      <div className="bg-hero-img bg-no-repeat bg-cover w-full  relative ">
        <div className="main_container1 py-56 pb-24">
          <div className="flex flex-col gap-8 items-center justify-center">
            <h2 className="text-troo-white"> {serviceDetails?.name || title}</h2>
            <ul className="flex flex-wrap gap-1 justify-center text-troo-white">
              <Link to="/">Home</Link>
              <span>/</span>
              {breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={index}>
                  <li
                    className={`capitalize text-troo-white ${
                      index === breadcrumbs.length - 1
                        ? "text-troo-secondary font-bold"
                        : ""
                    }`}
                  >
                    <Link
                      to={breadcrumb.path}
                      className={`${
                        breadcrumb.path === pathName
                          ? "text-troo-primary font-bold"
                          : ""
                      } ${breadcrumb.path === "/faq" ? "uppercase" : ""}`}
                    >
                      {breadcrumb.title.replace(/-/g, " ")}
                    </Link>
                  </li>
                  {index !== breadcrumbs.length - 1 && <span>/</span>}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
        <div className="hidden  sm:flex flex-wrap justify-between">
          <div className="bg-troo-secondary py-8 pr-6 text-troo-white inline-flex gap-3 items-center">
            <div className="w-24 h-[1px] bg-troo-white"></div>
            <Link className="hover:text-troo-primary">Social Media:</Link>
            <Link className="hover:text-troo-primary">Facebook</Link>
            <Link className="hover:text-troo-primary">Twitter</Link>
            <Link className="hover:text-troo-primary">Linkedin</Link>
            <Link className="hover:text-troo-primary">Instagram</Link>
          </div>

        </div>
      </div>
    </div>
  );
};

export default HeroGlobal;
