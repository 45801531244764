import blog1 from "../images/blog1.png";
import blog2 from "../images/blog2.png";
import blog3 from "../images/blog3.png";
import blog4 from "../images/blog4.png";
import blog5 from "../images/blog5.png";
import blog6 from "../images/blog6.png";
import blog7 from "../images/blog7.png";
import blog8 from "../images/blog8.png";

export const BlogData = [
  {
    id: 1,
    img: blog1,
    name: "Growing These Four Herbs in Your Garden is Super Easy",
    slug: "growing-these-four-herbs-in-your-garden-is-super-easy",
    date: "10 July, 2023",
    sub: "Lorem Ipsum is simply  and lorem dummy text of the printing and typesetting industry.",
  },
  {
    id: 2,
    img: blog2,
    name: "Green Ninja – The Urban Farming Workshop for Kids",
    slug: "green-ninja–the-urban-farming-workshop-for-kids",
    date: "10 July, 2023",
    sub: "Lorem Ipsum is simply  and lorem dummy text of the printing and typesetting industry.",
  },
  {
    id: 3,
    img: blog3,
    name: "When Blight Strikes, it’s Time To Be More Careful",
    slug: "when-blight-strikes-it’s-time-to-be-more-careful",
    date: "10 July, 2023",
    sub: "Lorem Ipsum is simply  and lorem dummy text of the printing and typesetting industry.",
  },
  {
    id: 4,
    img: blog4,
    name: "Ocean sunfish, tiger shark cat shark flat loach",
    slug: "ocean-sunfish-tiger-shark-cat-shark-flat-loach",
    date: "10 July, 2023",
    sub: "Lorem Ipsum is simply  and lorem dummy text of the printing and typesetting industry.",
  },
  {
    id: 5,
    img: blog5,
    name: "Summer Crop Nutrition and Fertilizer Requirements",
    slug: "summer-crop-nutrition-and-fertilizer-requirements",
    date: "10 July, 2023",
    sub: "Lorem Ipsum is simply  and lorem dummy text of the printing and typesetting industry.",
  },

  {
    id: 6,
    img: blog6,
    name: "Fresh Fruits & Vegetables in our New Markets",
    slug: "fresh-fruits-and-vegetables-in-our-new-markets",
    date: "10 July, 2023",
    sub: "Lorem Ipsum is simply  and lorem dummy text of the printing and typesetting industry.",
  },
  {
    id: 7,
    img: blog7,
    name: "It is Nice When Life Gives Green Air Planet",
    slug: "it-is-nice-when-life-gives-green-air-planet",
    date: "10 July, 2023",
    sub: "Lorem Ipsum is simply  and lorem dummy text of the printing and typesetting industry.",
  },
  {
    id: 8,
    img: blog8,
    name: "Livestock in our Agrarium Farm in Copenhagen city",
    slug: "livestock-in-our-agrarium-farm-in-copenhagen-city",
    date: "10 July, 2023",
    sub: "Lorem Ipsum is simply  and lorem dummy text of the printing and typesetting industry.",
  },
];
