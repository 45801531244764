import React from "react";
import Heading from "./Heading";
import { WorkData } from "../Data/WorkData";
import Counter from "./Counter";

const OurWork = () => {
  return (
    <div className="lg:bg-work-bg bg-no-repeat w-full h-full bg-cover bg-troo-secondary pt-12 lg:pt-24 pb-12 lg:pb-0  ">
      <div className="main_container1 relative">
        <div className="mb-12">
          <Heading
            title={
              <>
                There are many variations of passages of Lorem Ipsum available,
                but the majority <br />
                have suffered alteration in some form, by injected humour.
              </>
            }
            heading="How We Work Our Company"
            isCenter
            textLight
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 mb-24">
          {WorkData.map((e, i) => (
            <div className="flex flex-col items-center gap-5 px-6 lg:px-10 py-8 border border-troo-white border-opacity-10 rounded hover:border-troo-primary transition-all ease-in-out duration-500 text-troo-white relative bg-troo-secondary group" >
                <p className="absolute -top-5 p-2 bg-troo-black group-hover:bg-troo-primary group-hover:text-troo-black  rounded ">{e.sub}</p>
              <h3>{e.name}</h3>
              <div className="2xl:p-14 p-6 bg-troo-white bg-opacity-5">
                <img src={e.img} alt={e.name} />
              </div>
              <p className="text-center">{e.para}</p>
            </div>
          ))}
        </div>
        <Counter/>
      </div>
    </div>
  );
};

export default OurWork;
