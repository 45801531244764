import team1 from "../images/team1.png"
import team2 from "../images/team2.png"
import team3 from "../images/team3.png"
import team4 from "../images/team4.png"
import team5 from "../images/team5.png"
import team6 from "../images/team6.png"
import team7 from "../images/team7.png"
import team8 from "../images/team8.png"
import team9 from "../images/team9.png"

export const TeamData = [
    {
        id : 1,
        img : team1,
        name : "Alexa Brethfinix",
        slug : "alexa-breathfinix",
        sub : "SEO and Founder",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,",
        social : [
            {
                Sname : "FB",
                path : ""
            },
            {
                Sname : "TW",
                path : ""
            },
            {
                Sname : "LD",
                path : ""
            },
            {
                Sname : "LG",
                path : ""
            },
        ]
    },
    {
        id : 2,
        img : team2,
        name : "David Hangman",
        slug : "devid-hangman",
        sub : "SR. Gardenist",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,",
        social : [
            {
                Sname : "FB",
                path : ""
            },
            {
                Sname : "TW",
                path : ""
            },
            {
                Sname : "LD",
                path : ""
            },
            {
                Sname : "LG",
                path : ""
            },
        ]
    },
    {
        id : 3,
        img : team3,
        name : "Charlot Anderson",
        slug : "charlot-anderson",
        sub : "SR. Gardenist",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,",
        social : [
            {
                Sname : "FB",
                path : ""
            },
            {
                Sname : "TW",
                path : ""
            },
            {
                Sname : "LD",
                path : ""
            },
            {
                Sname : "LG",
                path : ""
            },
        ]
    },
    {
        id : 4,
        img : team4,
        name : "Juana Nicolas",
        slug : "juana-nicolas",
        sub : "SR. Gardenist",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,",
        social : [
            {
                Sname : "FB",
                path : ""
            },
            {
                Sname : "TW",
                path : ""
            },
            {
                Sname : "LD",
                path : ""
            },
            {
                Sname : "LG",
                path : ""
            },
        ]
    },
    {
        id : 5,
        img : team5,
        name : "Craig Barrows",
        slug : "craig-barrows",
        sub : "SR. Gardenist",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,",
        social : [
            {
                Sname : "FB",
                path : ""
            },
            {
                Sname : "TW",
                path : ""
            },
            {
                Sname : "LD",
                path : ""
            },
            {
                Sname : "LG",
                path : ""
            },
        ]
    },
    {
        id : 6,
        img : team6,
        name : "Luis Ledner",
        slug : "luis-ledner",
        sub : "SR. Gardenist",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,",
        social : [
            {
                Sname : "FB",
                path : ""
            },
            {
                Sname : "TW",
                path : ""
            },
            {
                Sname : "LD",
                path : ""
            },
            {
                Sname : "LG",
                path : ""
            },
        ]
    },
    {
        id : 7,
        img : team7,
        name : "Guadalupe Lebsack",
        slug : "guadalupe-lebsack",
        sub : "SEO and Founder",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,",
        social : [
            {
                Sname : "FB",
                path : ""
            },
            {
                Sname : "TW",
                path : ""
            },
            {
                Sname : "LD",
                path : ""
            },
            {
                Sname : "LG",
                path : ""
            },
        ]
    },
    {
        id : 8,
        img : team8,
        name : "Jamie Boyer",
        slug : "jamie-boyer",
        sub : "SR. Gardenist",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,",
        social : [
            {
                Sname : "FB",
                path : ""
            },
            {
                Sname : "TW",
                path : ""
            },
            {
                Sname : "LD",
                path : ""
            },
            {
                Sname : "LG",
                path : ""
            },
        ]
    },
    {
        id : 9,
        img : team9,
        name : "Dianne Kihn",
        slug : "dianne-kihn",
        sub : "SEO and Founder",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,",
        social : [
            {
                Sname : "FB",
                path : ""
            },
            {
                Sname : "TW",
                path : ""
            },
            {
                Sname : "LD",
                path : ""
            },
            {
                Sname : "LG",
                path : ""
            },
        ]
    },
]